import React, { useRef, useEffect, useState } from "react";
import { useLocation,  useNavigate } from "react-router-dom";
import "./courseDescription.css";
import RightArrow from "../../../assets/Images/arrow_right_alt.svg";
import Discord from "../../../assets/Images/Discord.svg";
import axios from "axios";
import CourseContentBox from "./CourseContentBox/CourseContentBox";
import MintCertificateBox from "./MintCertificateBox/MintCertificateBox";
import Footer from "../../Footer/Footer";
import web3button from "../../../assets/Images/web3button.svg";
import coursesbutton from "../../../assets/Images/coursesButton.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";

const CourseDescription = () => {
  const progressBarRef = useRef(null);
  const [tasks, setTasks] = useState("");
  const [courses, setCourses] = useState("");
  const progress = courses.courseCompletionPercentage;
  const [courseProgress, setCourseProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [screenShowing, setScreenShowing] = useState(1);
  const location = useLocation();
  const [courseDetails, setCourseDetails] = useState();
  const { courseId, courseName, level, courseType, linkOfTask, discordLink,submissionLink, fromGotoTask } = location.state || {};
  const [active, setActive] = useState(courseId);
  //const courseType = "Frontend";
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('jwtToken');
  const USN = localStorage.getItem('USN');
  const fullName = localStorage.getItem('fullName');
  const firstName = fullName ? fullName.split(' ')[0] : 'User';

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('USN');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userName');
    localStorage.removeItem('college');
    localStorage.removeItem('__v');
    navigate('/courselogin');
  };
 
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);


  useEffect(() => {
    setActive(courseId);
    console.log("active in course desc:",active);

  }, [courseId]);

  useEffect(() => {
    const progressBar = progressBarRef.current;
    setTimeout(() => {
      progressBar.style.width = `${progress}%`;
    }, 500);
  }, [progress]);

  const fetchCourseDetails = async () => {
    try {
      const jwt = localStorage.getItem("jwtToken");
      const res = await axios.get(
        `https://auth.web3onwards.com/course/${courseType}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            USNnumber: localStorage.getItem("USN"),
          },
        }
      );
      setCourseDetails(res.data.courseTypeDetails);
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  const fetchTasks = async (courseId) => {
    try {
      const jwt = localStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://auth.web3onwards.com/taskbycrsid`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            courseId: courseId,
            USNnumber: localStorage.getItem("USN"),
          },
        }
      );
      setTasks(response.data.Data.tasks);
      setCourses(response.data.Data.course);
      setCourseProgress(response.data.Data.course.courseCompletionPercentage);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchCourseDetails();
  }, [courseType]);

  useEffect(() => {
    if (courseId) {
      setActive(courseId);
    }
  }, [courseId]);

  useEffect(() => {
    if (active) {
      fetchTasks(active);
    }
  }, [active]);

  const progressBarStyles = {
    height: "2.25rem",
    backgroundColor: "#4CAF50",
    backgroundImage:
      "linear-gradient(135deg, rgba(0,0,0,.3) 25%, transparent 25%, transparent 50%, rgba(0,0,0,.3) 50%, rgba(0,0,0,.3) 75%, transparent 75%, transparent)",
    backgroundSize: "4.5rem 4.5rem",
    // textAlign: "center",
    lineHeight: "2.25rem",
    color: "white",
    width: "0%",
    transition: "width 1s ease-in-out",
  };

  // const handleScreenChange = (e) => {
  //   setScreenShowing(parseInt(e.target.id));
  // };

  console.log("Tasks", tasks);
  return (
    <>
      <div className="course-desc-container">
      <div className="nav-bar-container">
          <div className="page-navigation-container1">
          <div className="nav-button-container">
          <a href='/'>
          <img src={web3button} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/course'>
          <img src={coursesbutton} alt="coursesButton" />
          </a>
          </div>
          </div>
          <div className="coursepage-navigation-container">
          <div className="nav-button-container">
          <a href='/'>
          <img className='nav-contributors-button' src={navContributors} alt="web3button"/>
          </a>
          </div>
          {jwtToken && USN ? (
                <div className="nav-user-button-container">
                  <button className="user-button">Hi, {firstName}</button>
                  <button className="logout-button" onClick={handleLogout}>LOGOUT</button>
                </div>
                
              ) : (
                <div className="nav-button-container">
                <a href='/courselogin'>
                  <img className='nav-loginSignup-button' src={loginSignup} alt="loginSignup" />
                </a>
                </div>
              )}
          </div>
        </div>
        <div className="upper-box">
          <div className="box-1">
            <div className="box-container">
              {courseDetails?.map((data) => (
                <div
                  onClick={() => setActive(data.courseId)}
                  className={active === data.courseId ? "box-active" : "box"}
                >
                  {data.courseName}
                </div>
              ))}
            </div>
          </div>
          <div className="box-2">
            <div className="box">
              <div className="text">
                <div className="t-1">INTRODUCTION TO</div>
                <div className="t-2" >{courses?.courseDetails?.courseName}</div>
              </div>
            </div>
          </div>
          {/* <div className="box-3">
            <div className="desc-content">
              <div className="course-heading">COURSE DESCRIPTION</div>
              <div className="course-para">
                {courses?.courseDetails?.Description}
              </div>
            </div>
          </div> */}
        </div>
        <div className="lower-box">
          <div className="box-container">
            <div className="box-1">
              <div className="course-content-box">
                <div
                  id="1"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 1
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">COURSE CONTENTS</div>
                  <img src={RightArrow} alt="" />
                </div>
                <a href="/help">
                <div
                  id="7"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 7
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">HOW DO I SUBMIT MY CODE?</div>
                  <img src={RightArrow} alt="" />
                </div>
                </a>
                {/* <div
                  id="2"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 2
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">HOW TO START THIS COURSE?</div>
                  <img src={RightArrow} alt="" />
                </div>
                <div
                  id="3"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 3
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">
                    WHAT YOU WILL GET OUT OF THESE COURSE?
                  </div>
                  <img src={RightArrow} alt="" />
                </div>
                <div
                  id="4"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 4
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">MINT YOUR CERTIFICATE</div>
                  <img src={RightArrow} alt="" />
                </div>
                <div
                  id="5"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 5
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">SEE THE LEADERBOARD</div>
                  <img src={RightArrow} alt="" />
                </div>
                <div
                  id="6"
                  // onClick={handleScreenChange}
                  className={
                    screenShowing === 6
                      ? "course-content-title-active"
                      : "course-content-title"
                  }
                >
                  <div className="course-title">FAQS</div>
                  <img src={RightArrow} alt="" />
                </div> */}
              </div>
              <div className="course-progress-box">
                <div
                  className="progress-container"
                  style={{
                    width: "100%",
                    height: "2.25rem",
                    borderBottom: "0.25px solid #9c9c9c",
                    position: "relative",
                  }}
                >
                  <div
                    className="progress-bar"
                    ref={progressBarRef}
                    style={progressBarStyles}
                  >
                    <span className="progress-para">COURSE PROGRESS..</span>
                    <span className="progress-text">{progress}%</span>
                  </div>
                </div>
                <div className="help-section-container">
                  <div className="help-section">
                    <div className="help-section-heading">NEED HELP?</div>
                    <div className="help-section-para">
                    Got stuck? Join our interactive community on Discord! Our platform helps students upskill and get support when they encounter challenges. Connect with peers, access resources, and receive guidance from experienced mentors to succeed in learning.
                    </div>
                  </div>
                </div>
                <a href="https://discord.gg/GuMe3b5kFT" target="_blank">
                <img
                  style={{ position: "absolute", bottom: "0", right: "0" }}
                  src={Discord}
                  alt=""
                />
                </a>
              </div>
            </div>
            {screenShowing === 1 && <CourseContentBox tasksId={tasks} level={level} courseIdparam={active} linkOfTask={linkOfTask} discordLink={discordLink} submissionLink={submissionLink} fromGotoTask={fromGotoTask} />}
            {/* {screenShowing === 2 && <MintCertificateBox />} */}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default CourseDescription;
